<template>
  <div class="w-full pt-5 pb-5">
    <div class="flex">
      <back-button
        label="Back"
        @onClick="
          $router.push({
            name: 'EssAgrreements',
            query: { activeTab: 'My Agreements' },
          })
        "
        variant="secondary"
        class="mr-4"
      />
      <div
        class="mt-4"
        style="border-left: 1px solid #878e99; height: 1.5rem"
      />

      <div style="width: 220px">
        <h1 class="text-xl text-left font-extrabold ml-4 mt-3">
          New Agreement
        </h1>
      </div>

      <div class="w-7/12 mt-4">
        <Breadcrumb :items="breadcrumbs" />
      </div>
    </div>

    <ValidationObserver rules="required" v-slot="{ handleSubmit }">
      <div class="px-3">
        <card class="p-5 mt-3">
          <p class="text-lg font-bold">Agreement Details</p>
          <div class="grid grid-cols-12 gap-7" style="margin-top: 1.125rem">
            <div class="col-span-4">
              <div class="text-sm text-jet">Financial Year</div>
              <div
                class="date-flex mt-1"
                :style="periodError ? 'border : 1px solid red' : ''"
              >
                <datepicker
                  placeholder="--Select Day & Month & Year--"
                  style="width: 100%; outline: none"
                  input-class="date-input"
                  format="yyyy"
                  minimum-view="year"
                  @selected="periodSelect"
                  v-model="payload.year"
                />
              </div>
              <small
                data-v-d5a084b0=""
                class="mt-1 text-red-700 text-xs font-semibold"
                v-if="periodError"
                >Financial year is not valid.</small
              >
            </div>

            <div class="col-span-4">
              <c-text
                placeholder="--Enter--"
                variant="w-full"
                class="label mt-2"
                v-model="payload.description"
                label="Description"
                :rules="['required']"
                :disabled="true"
              />
            </div>

            <div class="col-span-4 label">
              <c-select
                label="Template"
                placeholder="Select Template"
                variant="w-full"
                class="mt-2"
                :options="templateOptions"
                v-model="selectedTemplate"
                :rules="['required']"
              />
            </div>
          </div>

          <div
            class="w-full p-2 h-auto flex flex-col mt-5 cycle"
            v-if="selectedTemplate !== ''"
          >
            <div class="w-11/12 flex cycle__text">CYCLE DETAILS</div>

            <card class="w-full fcard">
              <div class="w-full flex h-auto">
                <div class="fcard__icon">
                  <icon :icon-name="doccycle" size="csm" />
                </div>
                <div class="w-full flex flex-col h-auto">
                  <div class="fcard__header" style="color: #490166">
                    {{ cycleTitle }}
                  </div>
                  <div class="w-full fcard__text">
                    {{ cycleDescription }}
                  </div>
                </div>
              </div>
            </card>

            <div class="w-full h-auto flex flex-wrap">
              <card class="w-5/12 flex flex-grow mr-3 fcard">
                <div class="w-full flex h-auto">
                  <div class="fcard__icon">
                    <icon :icon-name="calendarIcon" size="csm" />
                  </div>
                  <div class="w-full flex flex-col h-auto">
                    <div class="fcard__header" style="color: #e99323">
                      Cycle Period
                    </div>
                    <div class="w-full fcard__text">
                      {{ $DATEFORMAT(new Date(cycleStartPeriod), "MMMM dd") }}
                      -
                      {{ $DATEFORMAT(new Date(cycleEndPeriod), "MMMM dd") }}
                    </div>
                  </div>
                </div>
              </card>

              <card
                class="w-5/12 flex flex-grow mb-3 fcard"
                v-for="(cycles, index) in appraisal_cycles"
                :key="index"
              >
                <div class="w-full flex h-auto">
                  <div class="fcard__icon">
                    <icon :icon-name="reviewIcon" size="csm" />
                  </div>
                  <div class="w-full flex flex-col h-auto">
                    <div class="fcard__header" style="color: #2176ff">
                      {{ cycles.name }}
                    </div>
                    <div class="w-full fcard__text">
                      {{
                        $DATEFORMAT(
                          new Date(cycles.appraisal_starts),
                          "MMMM dd"
                        )
                      }}
                      -
                      {{
                        $DATEFORMAT(new Date(cycles.appraisal_ends), "MMMM dd")
                      }}
                    </div>
                  </div>
                </div>
              </card>
            </div>
          </div>

          <div
            class="w-full p-2 h-auto flex flex-col mt-5"
            style="border: 1px dashed #878e99; border-radius: 5px"
            v-else
          >
            <div class="w-11/12 h-6 p-1 flex cyc-text">PERFORMANCE DETAILS</div>
            <div class="w-11/12 h-6 p-1 mt-4 mb-3 inner-text">
              --Select a Performance Template to view Appraisal Performance
              Details here---
            </div>
          </div>
        </card>
      </div>

      <div class="px-3">
        <card class="p-5" style="margin-top: 4px">
          <div
            class="w-full flex flex-row"
            style="margin-top: 20px; height: 44px; flex-grow: 1"
          >
            <div class="w-9/12 h-auto mt-2">
              <h1 class="text-base font-bold">KPAs</h1>
            </div>

            <div
              class="h-full w-auto flex flex-row"
              v-if="selectedTemplate !== ''"
            >
              <div
                class="p-2 mx-3 bg-ghostWhite h-full flex"
                style="border-radius: 5px"
              >
                <span class="text-sm font-black flex flex-grow">
                  Total Allocated Weight:
                </span>
                <span class="text-sm text-desire ml-3">
                  {{ allocatedWeight }}%
                </span>
                <icon :icon-name="iconInfo" size="ksm" />
              </div>
            </div>
          </div>

          <div style="border-bottom: 1px solid #878e99; height: 1rem" />

          <div
            class="w-full h-auto my-6 flex flex-col"
            style="align-items: center"
            v-if="templateKPATypes.length === 0"
          >
            <div
              class="w-5/12 h-auto mb-4 mt-4 flex"
              style="justify-content: center"
            >
              <icon
                :icon-name="illustration"
                size="auto"
                class-name="icon-Size mb-2"
              />
            </div>
            <div class="w-6/12 h-auto illu-text">
              You set organizational goals and objectives here. KPAs appear here
              when you select a template. You can edit selected template based
              on permissions assigned. Select a template to get started.
            </div>
          </div>

          <div class="flex flex-col w-full my-4" v-else>
            <!----- List of KPA's ---->

            <div class="w-full h-auto flex flex-col">
              <accordion
                class="mt-6"
                v-for="(kpaTypes, kpaNumber) in templateKPATypes"
                :key="kpaNumber"
                show-divider
                @open="handleOpen($event, kpaTypes.id)"
                :is-open="true"
              >
                <template v-slot:title>
                  <span class="font-bold">{{ kpaTypes.name }}</span>
                </template>

                <template v-slot:block>
                  <div
                    class="p-2 w-auto flex gap-2 items-center bg-ghostWhite rounded-sm mr-2"
                  >
                    <span
                      class="font-black text-xs"
                      :class="
                        getKPAWeight(kpaTypes, kpaTypes.id) >
                        getTotalWeight(kpaTypes, kpaTypes.id)
                          ? ''
                          : ''
                      "
                    >
                      {{ getKPAWeight(kpaTypes, kpaTypes.id) }}%
                    </span>
                  </div>
                </template>

                <div class="w-full">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <div class="w-full flex justify-end">
                      <div
                        class="text-flame font-semibold flex mt-3 mb-1 p-2 text-base cursor-pointer"
                        @click="handleSubmit(createNewKPA(kpaTypes))"
                      >
                        <icon icon-name="icon-plus" class="mr-2" size="xsm" />
                        Add New KPI
                      </div>
                    </div>

                    <scroll-container height="1000px">
                      <Table
                        :headers="KPAHeader"
                        :items="kpaTypes.kpas"
                        class="h-full my-2 table"
                        aria-label="absence table"
                        :has-number="false"
                      >
                        <template v-slot:item="{ item }">
                          <div v-if="item.state" class="-ml-3">
                            <div v-if="item.data.state" class="p-2">
                              <div class="flex flex-col">
                                <div
                                  class="cursor-pointer"
                                  v-if="item.data.isLocked"
                                  @click="
                                    lockKPA(item.data.isLocked, item.data)
                                  "
                                >
                                  <icon
                                    icon-name="icon-lock"
                                    size="xs"
                                    class="my-2"
                                  />
                                </div>
                                <div
                                  class="cursor-pointer"
                                  v-else
                                  @click="
                                    lockKPA(item.data.isLocked, item.data)
                                  "
                                >
                                  <icon
                                    icon-name="icon-unlock"
                                    size="xs"
                                    class="my-2"
                                  />
                                </div>
                                <div
                                  class="cursor-pointer"
                                  @click="deleteKPA(item.index, kpaTypes)"
                                  v-if="!item.data.isLocked"
                                >
                                  <icon
                                    icon-name="icon-trash"
                                    size="xs"
                                    class="my-2"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div v-else-if="item.name">
                            <div class="label">
                              <c-text
                                placeholder="--Enter KPI--"
                                variant="w-full"
                                class="mr-2 -ml-1 -mt-1 w-72"
                                :disabled="item.data.isLocked"
                                v-tooltip.bottom-middle="item.data.name"
                                v-model="item.data.name"
                                :rules="inputRules"
                                label="KPI"
                              />
                            </div>
                          </div>

                          <div v-else-if="item.objective">
                            <div class="label">
                              <c-text
                                placeholder="--Enter Objective--"
                                variant="w-full"
                                class="mr-2 -ml-1 -mt-2 w-72"
                                :disabled="item.data.isLocked"
                                v-model="item.data.objective"
                                v-tooltip.bottom-middle="item.data.objective"
                                :rules="inputRules"
                                label="Objective"
                              />
                            </div>
                          </div>

                          <div v-else-if="item.kpis" class="flex">
                            <div
                              class="label flex"
                              v-for="(n, rating) in item.data.kpis"
                              :key="rating"
                            >
                              <ValidationObserver>
                                <c-text
                                  variant="w-full"
                                  :class="`mr-2 -mt-2 w-48`"
                                  :placeholder="`Criteria ${rating}`"
                                  :disabled="item.data.isLocked"
                                  v-model="item.data.kpis[rating].criteria"
                                  v-tooltip.bottom-middle="
                                    item.data.kpis[rating].criteria
                                  "
                                  :rules="inputRules"
                                  :label="`Criteria ${rating} value`"
                                />
                              </ValidationObserver>
                            </div>
                          </div>

                          <div v-else-if="item.target_date">
                            <div class="label w-72">
                              <div
                                class="date-flex -mt-2"
                                :class="item.data.isLocked ? 'bg-disabled' : ''"
                              >
                                <datepicker
                                  placeholder="--Select Day & Month & Year--"
                                  style="width: 100%; outline: none"
                                  input-class="date-input"
                                  format="yyyy-MMMM-dd"
                                  v-model="item.data.target_date"
                                  v-tooltip.bottom-middle="
                                    item.data.target_date
                                  "
                                  :disabled="item.data.isLocked"
                                />
                              </div>
                            </div>
                          </div>

                          <div v-else-if="item.weight">
                            <div class="label">
                              <c-text
                                placeholder="--Enter Weight--"
                                variant="w-full"
                                class="mr-2 -ml-1 -mt-2 w-24"
                                type="number"
                                min="0"
                                max="100"
                                :rules="weightRules"
                                v-model="item.data.weight"
                                v-tooltip.bottom-middle="item.data.weight"
                                :disabled="item.data.isLocked"
                                label="Weight"
                              />
                            </div>
                          </div>
                        </template>
                      </Table>
                    </scroll-container>
                  </ValidationObserver>
                </div>
              </accordion>
            </div>
          </div>
        </card>
      </div>

      <div class="pl-3 flex mt-5">
        <div class="flex flex-grow">
          <Button
            :disabled="disableBtn"
            :class="disableBtn ? `btn-disabled` : `bg-dynamicBackBtn text-white`"
            @click="handleSubmit(checkSelected('Share for Review'))"
            v-if="userReportingTo"
          >
            <span class="mr-6 ml-6">Share for Review</span>
          </Button>

          <Button
            :disabled="disableBtn"
            :class="disableBtn ? `btn-disabled` : `bg-dynamicBackBtn text-white`"
            @click="handleSubmit(checkSelected('Submit'))"
            v-else
          >
            <span class="mr-6 ml-6">Submit</span>
          </Button>

          <Button
            background-color="none"
            class="ml-4"
            style="color: #321c3b"
            @click="cancel()"
          >
            Cancel
          </Button>
        </div>
        <div class="flex justify-end mr-5">
          <Button
            class="text-dynamicBackBtn border border-solid border-dynamicBackBtn text-center"
            width="8rem"
            @click="submit('draft')"
            v-if="payload.status === 'draft' && payload.description !== ''"
          >
            Save as Draft
          </Button>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import Datepicker from "vuejs-datepicker";
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import CText from "@scelloo/cloudenly-ui/src/components/text";
import Table from "@scelloo/cloudenly-ui/src/components/table";
import CSelect from "@scelloo/cloudenly-ui/src/components/select";
import Card from "@/components/Card";
import Icon from "@/components/Icon";
import Button from "@/components/Button";
import ScrollContainer from "@/components/ScrollContainer";
import Accordion from "@/components/Accordion";
import paramsMixin from '@/utilities/paramsMixin';
import timeFormatMixin from "@/utilities/timeFormatMixin";

export default {
  mixins: [paramsMixin, timeFormatMixin],
  components: {
    ValidationObserver,
    BackButton,
    Breadcrumb,
    Icon,
    CText,
    Card,
    Button,
    CSelect,
    Accordion,
    Table,
    ScrollContainer,
    Datepicker,
  },
  data() {
    return {
      breadcrumbs: [
        {
          disabled: false,
          text: "Performance",
          href: "performance",
          id: "Performance",
        },
        {
          disabled: false,
          text: "Performance Agreement",
          href: "Performance Agreement",
          id: "Agreement",
        },
        {
          disabled: false,
          text: "My Agreements",
          href: "My Agreements",
          id: "My Agreements",
        },
        {
          disabled: false,
          text: "New Agreement",
          id: "New Agreement",
        },
      ],
      illustration: "illustration",
      allocatedWeight: 0,
      templateKPATypes: [],
      weightRules: [
        "required",
        {
          name: "weight",
          rule: (w) => this.validateWeight(w),
        },
      ],
      inputRules: [
        "required",
        {
          name: "data",
          rule: (w) => this.validateEntries(w),
        },
      ],
      KPAHeader: [
        { title: "•", value: "state" },
        { title: "KPA", value: "name" },
        { title: "Objective", value: "objective" },
        { title: "Criteria", value: "kpis" },
        { title: "Target Date", value: "target_date" },
        { title: "Weight (%)", value: "weight" },
      ],
      loadingBar: true,
      moreIcon: "more_icon",
      doccycle: "doc-cycle",
      calendarIcon: "calendar",
      reviewIcon: "reviewicon",
      starreviewIcon: "star-review",
      iconInfo: "icon-info",
      iconPlus: "icon-plus",
      iconCopy: "icon-copy",
      iconEdit: "icon-edit",
      iconChevron: "chevronRight",
      disableBtn: true,
      watchOthers: false,
      addOthersModal: false,
      previousGoalModal: false,
      userReportingTo: null,
      periodError: false,
      templateIds: [],
      Ratings: [],
      ratingsIds: [],
      templateOptions: [],
      validateKPA: [],
      goalsKPA: [],
      selectedTemplate: "",
      cycleStartPeriod: "",
      cycleEndPeriod: "",
      cycleDescription: "",
      cycleTitle: "",
      appraisal_cycles: [],
      kpaData: [],
      validateKpa: "",
      reportingTo: "",
      applicableEmployees: [],
      managerLock: false,
      hasDirectReport: false,
      payload: {
        orgId: "",
        templateId: "",
        goalId: "",
        title: "",
        status: "draft",
        created_by: "",
        created_for: "",
        share_for_review: false,
        year: "",
        description: "",
        kpa_types: [],
      },
      kpaWeights: [],
      totalWeight: "",
    };
  },

  watch: {
    selectedTemplate(value) {
      this.$_getKPAWeights(value).then((response) => {
        const kpaData = response.data.data;

        this.kpaWeights = kpaData;

        let sum = 0;

        this.kpaWeights.forEach((kpa) => {
          sum += kpa.totalKpiWeight;
        });

        this.totalWeight = sum;
      });

      this.$_getOneTemplate(value).then((response) => {
        this.disableBtn = false;

        this.payload.templateId = value;

        this.cycleDescription =
          response.data.PerformanceTemplate.agreement_description;

        this.cycleTitle = response.data.PerformanceTemplate.agreement_title;

        this.payload.description = this.cycleDescription;

        this.goalsKPA.filter((goalKPA) => {
          if (goalKPA.id === value) {
            this.templateKPATypes = goalKPA.kpa_types;

            this.templateKPATypes.forEach((kpaType) => {
              const kpaData = kpaType;

              const newKPAs = [];

              if (kpaType.kpas.length > 0) {
                kpaType.kpas.map((kpasData) => {
                  newKPAs.push({
                    state: true,
                    id: kpasData.id,
                    isLocked: kpasData.isLocked,
                    name: kpasData.name,
                    objective: kpasData.objective,
                    kpis: this.getCriteriaData(kpasData.kpi),
                    target_date: this.resetDate(kpasData.target_date),
                    weight: kpasData.weight,
                  });
                  return {};
                });

                kpaData.kpas = newKPAs;
              } else {
                kpaType.kpas.push({
                  state: true,
                  id: "",
                  isLocked: false,
                  name: null,
                  objective: null,
                  kpis: this.getCriteriaData(),
                  target_date: null,
                  weight: "",
                });
              }
            });
          }

          return {};
        });
      });
    },
  },

  methods: {
    periodSelect() {
      this.periodError = false;
    },

    getKPAWeight(kpaTypes, kpaTypesId) {
      let sum = 0;

      if (kpaTypes.id === kpaTypesId) {
        kpaTypes.kpas.forEach((kpi) => {
          sum += Number(kpi.weight);
        });

        this.kpaWeights.filter((kpa) => {
          if (kpa.id === kpaTypesId) {
            // eslint-disable-next-line no-param-reassign
            kpa.weight = sum;
          }
          return {};
        });
      }

      return sum || 0;
    },

    getTotalWeight(kpaTypes, kpaTypesId) {
      let sum = 0;
      this.kpaWeights.filter((kpa) => {
        if (kpa.id === kpaTypesId) {
          sum = kpa.totalKpiWeight;
        }
        return {};
      });

      return sum;
    },

    lockKPA(isLocked, data) {
      if (this.managerLock && this.hasDirectReport) {
        // eslint-disable-next-line no-param-reassign
        data.isLocked = !isLocked;
      } else {
        this.$toasted.error(
          "You do not have the privilege to lock or unlock an agreement",
          { duration: 5000 }
        );
      }
    },

    resetDate(value) {
      if (value) {
        const dateValue = value;

        const split = dateValue.split("T00:00:00.000Z");

        return `${split[0]}T13:00:00.000Z`;
      }
      return "";
    },

    validateWeight(weight) {
      let sum = 0;
      if (weight < 0) {
        return "Value must be greater than or equal to 0";
      }

      this.templateKPATypes.forEach((kpaType) => {
        kpaType.kpas.map((kpi) => {
          sum += Number(kpi.weight);
          return {};
        });
        return {};
      });

      this.allocatedWeight = sum;

      if (sum !== 100) {
        this.disableBtn = true;
      } else {
        this.disableBtn = false;
      }

      return true;
    },

    validateEntries(t) {
      if (t !== "") {
        return true;
      }

      return false;
    },

    handleOpen(state) {
      if (state) {
        this.disableBtn = false;
      } else {
        this.disableBtn = true;
      }
    },

    submit(status) {
      this.kpaData = [];
      let isSatisfied = true;

      this.templateKPATypes.forEach((kpaType) => {
        const newKPAs = kpaType.kpas.map(({ state, ...rest }) => ({
          ...rest,
        }));

        newKPAs.map((kpi) => {
          const kpiData = kpi;
          kpiData.target_date = this.formatDate(kpiData.target_date, "arrange");

          return {};
        });

        const validation = newKPAs.every(
          (item) => item.name || item.objective || item.weight
        );

        if (validation) {
          this.kpaData.push({
            kpa_types_id: kpaType.id,
            kpas: newKPAs,
          });
        } else {
          this.kpaData.push({
            kpa_types_id: kpaType.id,
            kpas: [],
          });
        }

        return {};
      });

      this.payload.kpa_types = this.kpaData;
      this.payload.orgId = this.$orgId;
      this.payload.created_by = this.$AuthUser.id;
      this.payload.status = status;
      this.payload.senderId = this.$AuthUser.id;

      if (!status) {
        this.payload.agreement_status = 'shared';
      }

      if (status === "draft") {
        this.payload.created_for = this.$AuthUser.id;
      }

      if (!status) {
        this.kpaWeights.map((kpaWeight) => {
          if (kpaWeight.weight > kpaWeight.totalKpiWeight) {
            isSatisfied = false;
          }
          return {};
        });
      }

      if (this.payload.year !== "") {
        this.payload.year = this.payload.year.getFullYear();
        if (isSatisfied) {
          this.payload.title = this.payload.description;
          this.disableBtn = true;
          this.$_createEmployeeAgreement(this.payload)
            .then((response) => {
              this.$toasted.success(response.data.message, { duration: 5000 });
              this.$router.push({
                name: "EssAgrreements",
                query: { activeTab: "My Agreements" },
              });
            })
            .catch((error) => {
              this.$toasted.error(error.response.data.message, {
                duration: 5000,
              });
            });
        } else {
          this.$toasted.error(
            "Allocated weights of some KPA Types is greater than their set weight",
            {
              duration: 5000,
            }
          );
          this.payload.year = new Date(this.payload.year, 0);
          this.payload.status = "draft";
        }
      } else if (this.payload.year === "") {
        this.periodError = true;
        this.payload.status = "draft";
      }
    },

    createNewKPA(kpa) {
      const currentIndex = (kpa.kpas.length - 1);
      const arrayObj = kpa.kpas[currentIndex];
      let isEmpty = true;

      if (
        arrayObj.name !== "" &&
        arrayObj.objective !== "" &&
        arrayObj.weight !== ""
      ) {
        // iterate over criteria array
        arrayObj.kpis.forEach((v) => {
          if (Object.keys(v).every((k) => v[k] !== "")) {
            isEmpty = false;
          }
        });
      }

      this.addKPATable(kpa.id, isEmpty);
    },

    addKPATable(kpaId, valid) {
      if (!valid) {
        if (this.allocatedWeight < 100) {
          this.templateKPATypes.forEach((kpaType) => {
            if (kpaType.id === kpaId) {
              kpaType.kpas.push({
                state: true,
                isLocked: false,
                name: null,
                objective: null,
                kpis: this.getCriteriaData(),
                target_date: "",
                weight: "",
              });
            }
          });
        } else {
          this.$toasted.error(
            "Total allocated weight for all KPA's cannot be greater than 100%",
            { duration: 5000 }
          );
        }
      }
    },

    getCriteriaData(kpis) {
      const criteriaData = [];
      const sortedRatings = this.Ratings.sort((a, b) => a.rating - b.rating);

      this.Ratings = sortedRatings;

      if (kpis) {
        this.Ratings.map((rating, index) => {
          criteriaData.push({
            id: kpis[index].id,
            performanceRatingId: rating.id,
            criteria: kpis[index].criteria,
          });
          return {};
        });
      } else {
        this.Ratings.map((rating) => {
          criteriaData.push({
            performanceRatingId: rating.id,
            criteria: "",
          });
          return {};
        });
      }

      return criteriaData;
    },

    checkSelected(value) {
      this.templateKPATypes.forEach((kpaType) => {
        const newKPAs = kpaType.kpas;

        const validation = newKPAs.every(
          (item) => item.name || item.objective || item.weight
        );

        this.validateKPA.push(validation);
      });

      if (this.validateKPA.every((valid) => valid === true)) {
        if (value === "Submit") {
          this.payload.share_for_review = false;
          this.payload.created_for = this.$AuthUser.id;
          this.payload.approveAndSubmit = true;
          this.submit("active");
        } else {
          this.payload.share_for_review = true;
          this.payload.created_for = this.$AuthUser.id;
          this.submit("active");
        }
      } else {
        this.validateEntries("");
      }
    },

    getTemplates() {
      let templatesArr = [];

      this.$_getPerfomanceTemplate('').then((response) => {
        templatesArr = response.data.PerformanceTemplate;

        templatesArr.filter((template) => {
          if (template.status !== "draft") {
            const found = this.templateIds.some(
              (el) => el.templateId === template.id
            );
            if (found) {
              this.templateIds.filter((templateObj) => {
                if (
                  templateObj.employees.includes(this.$AuthUser.id) &&
                  templateObj.templateId === template.id
                ) {
                  this.templateOptions.push({
                    id: template.id,
                    name: template.title,
                  });
                }
                return {};
              });
            }
          }
          return {};
        });

        this.loading = false;
      });
    },

    getGoals() {
      const currentYear = new Date().getFullYear();
      this.$_getOrganizationGoal('').then((response) => {
        const goalData = response.data.goals;
        const ids = [];
        const employeesApplicable = [];
        const employees = [];
        const idNames = [];

        goalData.forEach((goals) => {
          if (idNames.includes(goals.goal.goal_performance_templates.id)) {
            ids.filter((idData) => {
              if (idData === goals.goal.goal_performance_templates.id) {
                idData.employees.push(goals.appliedEmployees);
              }
              return {};
            });
          } else {
            ids.push({
              templateId: goals.goal.goal_performance_templates.id,
              employees: goals.appliedEmployees,
            });
          }

          idNames.push(goals.goal.goal_performance_templates.id);

          employeesApplicable.push(goals.appliedEmployees);
          this.goalsKPA.push(goals.goal.goal_performance_templates);

          if(goals.goal.year === currentYear) {
            this.payload.goalId = goals.goal.id;
          }
        });

        employeesApplicable.map((employee) => {
          employee.map((employeeData) => {
            employees.push(employeeData);
            return {};
          });
          return {};
        });

        this.templateIds = ids;

        this.applicableEmployees = [...new Set(employees)];
        this.getTemplates();
      });
    },

    deleteKPA(index, kpaTypes) {
      if(kpaTypes.kpas.length > 1) {
      kpaTypes.kpas.splice(index, 1);
      }
      else {
        this.$toasted.error('Cannot delete ALL KPIs of a KPA Type, you can edit KPI values instead', {duration: 5000});
      }
    },

    viewEmployeeDetails(employeeId) {
      const payload = { id: employeeId };

      this.$_getOneEmployeeAll(payload).then((response) => {
        this.userReportingTo = response.data.userReportingTo;
      });
    },

    getAppraisalCycles() {
      this.$_getAppraisalCycle(this.agreementQuery()).then((response) => {
        const appraisal = response.data.AppraisalCycle;

        appraisal.map((appraisalCycles) => {
           const startDate = this.setDates(`${
            appraisalCycles.cycle_start_date}`);

          const endDate = this.setDates(`${
            appraisalCycles.cycle_end_date}`);

          this.cycleStartPeriod = startDate.toISOString();

          this.cycleEndPeriod = endDate.toISOString();

          appraisalCycles.cycles.forEach((cycle) => {
            const startCycleDate = this.setDates(`${
              cycle.appraisal_starts}`);

            const endCycleDate = this.setDates(`${
              cycle.appraisal_ends}`);

            this.appraisal_cycles.push({
              name: cycle.name,
              appraisal_starts: new Date(
                startCycleDate
              ).toISOString(),
              appraisal_ends: new Date(endCycleDate).toISOString(),
            });
          });

          return {};
        });
      });
    },

    cancel() {
      this.selectedTemplate = "";
      this.payload.title = "";
      this.templateKPATypes = [];
      this.disableBtn = true;
      this.$router.back();
    },

    getSettings() {
      this.$_getPerformanceGeneralSetting().then((response) => {
        const settings = response.data.PerformanceSettings;

        this.managerLock = settings[0].managerCanLockAgreement;
      });
    },

    getDirectReports() {
      this.$_getManagerDirectReport(this.$AuthUser.id).then((response) => {
        if (response.data.directReport.length > 0) {
          this.hasDirectReport = true;
        } else {
          this.hasDirectReport = false;
        }
      });
    },
  },

  mounted() {
    this.$_getPerfomanceRating().then((response) => {
      this.Ratings = response.data.PerformanceRatings;
    });
    this.getGoals();
    this.viewEmployeeDetails(this.$AuthUser.id);
    this.getAppraisalCycles();
    this.getSettings();
    this.getDirectReports();
  },
};
</script>

<style>
.bg-disabled {
  background-color: #eeeeee;
  border-color: transparent;
}

.cyc-text {
  font-size: 12px;
  font-weight: 900;
  line-height: 1.3rem;
  color: #878e99;
}

.inner-text {
  font-size: 12px;
  line-height: 1.9rem;
  font-weight: 600;
  text-align: center;
  color: #878e99;
  text-transform: uppercase;
}

.label label {
  font-size: 10px;
}

.cycle {
  border: 1px dashed #878e99;
  border-radius: 5px;
  padding: 12px;
}

.cycle__text {
  font-size: 12px;
  font-weight: 900;
  line-height: 1.3rem;
  color: #878e99;
  letter-spacing: 0.035em;
  height: 17px;
}

.fcard {
  padding: 12px;
  height: 58px;
  margin-top: 18px;
  line-height: 16.8px;
  text-transform: uppercase;
}

.fcard__icon {
  margin-right: 7.5px;
}

.csm {
  width: 12px;
  height: 12px;
}

.fcard__header {
  font-weight: 900;
  font-size: 12px;
  margin-top: -2px;
  letter-spacing: 0.035em;
}

.fcard__text {
  font-weight: 600;
  font-size: 14px;
  color: #333333;
  margin-right: 7.5px;
  letter-spacing: 0.015em;
}

.allocate {
  width: 320px;
  border-radius: 5px;
  padding: 8px;
  background-color: #f7f7ff;
  font-weight: 900;
  font-size: 12px;
  letter-spacing: 0.035em;
}

.allocate__text {
  line-height: 1.4rem;
  text-transform: uppercase;
  color: #321c3b;
}

.allocate__value {
  line-height: 17px;
  color: #ea3c53;
  margin: 3px 10px;
}

.line {
  width: 24px;
  height: 0px;
  transform: rotate(90deg);
  margin-left: 6px;
  margin-right: 6px;
}

.line--dashed {
  border: 0.5px dashed #878e99;
}

.line--straight {
  border: 1px solid #878e99;
}

.kpaplus {
  width: 180px;
  border-radius: 5px;
  background-color: #ffffff;
  padding: 10px;
}

.kpaplus__text {
  font-weight: 900;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0.035em;
  margin: 3px 10px;
  color: #f15a29;
}

.ksm {
  margin-left: 11px;
  color: #ea3c53;
  width: 14px;
  height: 14px;
  margin-top: 4px;
}

.kpalist__text {
  font-size: 18px;
  font-weight: bold;
  line-height: 120%;
  letter-spacing: 0.015em;
  color: #321c3b;
  width: 170px;
  height: 22px;
}

.kpalisticon {
  width: 18px;
  height: 18px;
}

.button-layout {
  width: 270px;
  height: 44px;
  margin-top: 29px;
}

.btn-disabled {
  background-color: #eeeeee;
  font-size: 16px;
  cursor: disabled;
}

details > summary {
  display: inline;
  list-style: none;
}

.icon-Size {
  width: 200px;
  height: 200px;
}
.illu-text {
  text-align: center;
  font-weight: 400;
  line-height: 19px;
  font-size: 16px;
  letter-spacing: 0.015em;
}
</style>